import { Group, Stack, Text, Title, Tooltip } from '@mantine/core'
import { apiCall } from '../../components/api/api'
import { useQuery } from '@tanstack/react-query'

export function RelatedServices() {
  const { data, isLoading, error } = useQuery({
    queryKey: ['related_services'],
    queryFn: async () => {
      const resData = await apiCall('service/related', 'get')
      return resData.items
    },
  })
  return (
    <>
      <Title>Related services</Title>
      {isLoading && <div>Loading...</div>}
      {error && <div>Error: {JSON.stringify(error)}</div>}
      <Stack gap={'xl'}>
        {data &&
          Object.keys(data).map((serviceId) => {
            const serviceRelation = data[serviceId]
            return (
              <div key={serviceRelation.id}>
                <div>
                  {serviceRelation.completedByServiceTypes.map((relatedServiceId: string) => (
                    <Group>
                      <Service id={serviceId} />
                      <Text>is completed by</Text>
                      <Service id={relatedServiceId} />
                    </Group>
                  ))}
                </div>
              </div>
            )
          })}
      </Stack>
    </>
  )
}

function Service({ id }: { id: string }) {
  const { data } = useQuery({
    queryKey: ['services', 'detail', id],
    queryFn: async () => {
      const resData = await apiCall(`service/${id}`, 'get')
      return resData
    },
  })

  return (
    <div>
      {data && (
        <Tooltip label={data.id}>
          <Text>{data.name}</Text>
        </Tooltip>
      )}
    </div>
  )
}
