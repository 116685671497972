import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import Login from './pages/auth/login/login'
import HomePage from './pages/home/home'
import PromoPage from './pages/promo/promo'
import SubscriptionPage from './pages/subscription/subscription'
import ConsumerPage from './pages/consumer/consumer'
import VehiclePage from './pages/vehicle/vehicle'
import MaintenancePage from './pages/maintenance/maintenance'
import DiagnosticReportPage from './pages/diagnostic_report/diagnostic_report'
import Services from './pages/services/services'
import { store } from './redux/store'
import { Provider as ReduxProvider } from 'react-redux'
import InvoiceReportPage from './pages/invoice_report/invoice_report'
import '@mantine/core/styles.css'
import '@mantine/carousel/styles.css'
import { createTheme, MantineProvider } from '@mantine/core'
import { RelatedServices } from './pages/related_services/related_services'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const theme = createTheme({
  /** Put your mantine theme override here */
})

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <ReduxProvider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/promo" element={<PromoPage />} />
              <Route path="/subscription" element={<SubscriptionPage />} />
              <Route path="/consumer" element={<ConsumerPage />} />
              <Route path="/vehicle" element={<VehiclePage />} />
              <Route path="/maintenance" element={<MaintenancePage />} />
              <Route path="/diagnostic_report" element={<DiagnosticReportPage />} />
              <Route path="/invoice_report" element={<InvoiceReportPage />} />
              <Route path="/services" element={<Services />} />
              <Route path="/related_services" element={<RelatedServices />} />
            </Routes>
          </BrowserRouter>
        </ReduxProvider>
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
